import { useContext } from "react";
import { WorkspaceContext } from "@/providers/workspaces/WorkspaceProvider";
import type {
  Workspace,
  CreateWorkspaceInput,
  UpdateWorkspaceInput,
} from "@/api/workspace";

interface UseWorkspaceReturn {
  workspaces: Workspace[];
  currentWorkspace: Workspace | null;
  currentWorkspaceId: string | null;
  setCurrentWorkspace: (workspaceId: string) => void;
  createWorkspace: (
    input: Omit<CreateWorkspaceInput, "organizationId">,
  ) => Promise<void>;
  updateWorkspace: (id: string, input: UpdateWorkspaceInput) => Promise<void>;
  deleteWorkspace: (id: string) => Promise<void>;
  error: string | null;
  hasInvalidWorkspaceInUrl: boolean;
  loading: boolean;
}

export const useWorkspace = (): UseWorkspaceReturn => {
  const context = useContext(WorkspaceContext);
  if (!context) {
    throw new Error("useWorkspace must be used within a WorkspaceProvider");
  }
  return context;
};
