"use client";

import { createContext, useState, useEffect, useMemo, ReactNode, useCallback } from "react";
import { useRouter, usePathname } from "next/navigation";
import { getWorkspacesAPI, createWorkspaceAPI, updateWorkspaceAPI, deleteWorkspaceAPI, Workspace, CreateWorkspaceInput, UpdateWorkspaceInput } from "@/api/workspace";
import { useOrganization } from "@/hooks/organization";
import { useNotice } from "@/components/edges/ui/Notice/NoticeProvider";
import { useApolloClient, ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { useEventEmitter } from "../event-bus";
import { useDashboardStorage, DASHBOARD_STORAGE_KEYS } from "@/hooks/useDashboardStorage";
import { useTranslations } from "next-intl";
import { workspaceNotices } from "./notices";
import { WorkspaceContextType } from "./types";
import { useWorkspaceIdFromPath, useInvalidWorkspaceUrl, useCurrentWorkspaceId } from "./hooks";
export const WorkspaceContext = createContext<WorkspaceContextType | null>(null);
export const WorkspaceProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  // Core state
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Hooks
  const {
    currentOrganization
  } = useOrganization();
  const {
    addNotice
  } = useNotice();
  const client = useApolloClient();
  const router = useRouter();
  const emitter = useEventEmitter();
  const t = useTranslations("loading");
  const pathname = usePathname();

  // Storage preferences
  const {
    value: organizationWorkspacePrefs = {},
    setValue: setOrganizationWorkspacePrefs
  } = useDashboardStorage<Record<string, string>>({
    key: DASHBOARD_STORAGE_KEYS.CURRENT_WORKSPACE_ID,
    initialValue: {}
  }) || {
    value: {},
    setValue: () => {}
  };

  // Workspace ID management
  const pathWorkspaceId = useWorkspaceIdFromPath();
  const hasInvalidWorkspaceInUrl = useInvalidWorkspaceUrl(pathWorkspaceId, workspaces);
  const storedWorkspaceId = useMemo(() => {
    try {
      if (!currentOrganization?.id || !organizationWorkspacePrefs) return null;
      const prefValue = organizationWorkspacePrefs[currentOrganization.id];
      return prefValue && prefValue !== "" ? prefValue : null;
    } catch (error) {
      console.error("[WorkspaceProvider] Error getting stored workspace ID:", error);
      return null;
    }
  }, [currentOrganization?.id, organizationWorkspacePrefs]);
  const setStoredWorkspaceId = useCallback((workspaceId: string | null) => {
    try {
      if (!currentOrganization?.id || !organizationWorkspacePrefs) return;
      setOrganizationWorkspacePrefs(prev => ({
        ...prev,
        [currentOrganization.id]: workspaceId || ""
      }));
    } catch (error) {
      console.error("[WorkspaceProvider] Error setting stored workspace ID:", error);
    }
  }, [currentOrganization?.id, organizationWorkspacePrefs, setOrganizationWorkspacePrefs]);

  // Core data fetching
  const fetchWorkspaces = useCallback(async (flowId?: string) => {
    if (!currentOrganization?.id) return;
    try {
      setError(null);
      setLoading(true);
      const wsList = await getWorkspacesAPI(client as ApolloClient<NormalizedCacheObject>, currentOrganization.id);
      setWorkspaces(wsList);
    } catch (error) {
      console.error("[WorkspaceProvider] Failed to fetch workspaces:", error);
      setError("Failed to load workspaces");
      addNotice(workspaceNotices.fetchError);
    } finally {
      setLoading(false);
      if (flowId) {
        emitter.emit("loading:end", {
          flowId
        });
      }
    }
  }, [currentOrganization?.id, client, addNotice, emitter]);

  // Organization change handling
  useEffect(() => {
    if (currentOrganization) {
      fetchWorkspaces();
    } else {
      setWorkspaces([]);
      setStoredWorkspaceId(null);
      setError(null);
    }
  }, [currentOrganization, fetchWorkspaces, setStoredWorkspaceId]);

  // Organization initialization handling
  useEffect(() => {
    const handleOrganizationInitialized = async ({
      currentOrganizationId,
      flowId
    }: {
      currentOrganizationId: string;
      flowId?: string;
    }) => {
      try {
        emitter.emit("loading:update", {
          text: t("workspaces"),
          flowId
        });
        const newWorkspaces = await getWorkspacesAPI(client as ApolloClient<NormalizedCacheObject>, currentOrganizationId);
        setWorkspaces(newWorkspaces);
        if (newWorkspaces.length === 0) {
          if (flowId) emitter.emit("loading:end", {
            flowId
          });
          return;
        }
        const storedId = organizationWorkspacePrefs[currentOrganizationId];
        const validStoredWorkspace = storedId && newWorkspaces.some(w => w.id === storedId);
        const targetWorkspaceId = validStoredWorkspace ? storedId : newWorkspaces[0]?.id;
        if (targetWorkspaceId) {
          setStoredWorkspaceId(targetWorkspaceId);

          // Check if we're already in a valid workspace route
          const isWorkspacesRoute = pathname?.includes("/workspaces");

          // Only prevent redirect on specific pages or if we're already in a valid workspace route
          const isOrgSwitchPage = pathname?.includes("/switch-organizations");
          const isInviteMemberPage = pathname?.includes("/invite-member");
          const isTestInvitesPage = pathname?.includes("/test-invites");
          const isInvitationsPage = pathname?.includes("/invitations");
          const isCreateOrgPage = pathname?.includes("/create-organization");
          const isSettingsPage = pathname?.includes("/settings");
          const isInitialLoad = !flowId;
          const shouldRedirect = (!isInitialLoad || !isOrgSwitchPage && !isTestInvitesPage && !isInvitationsPage && !isCreateOrgPage && !isSettingsPage && !isInviteMemberPage) &&
          // Don't redirect if we're already in a valid workspace route
          !isWorkspacesRoute;
          if (shouldRedirect) {
            emitter.emit("navigation:navigate", {
              path: `/workspaces/${targetWorkspaceId}/home`,
              options: {
                replace: true
              },
              flowId
            });
            emitter.emit("workspace:switched", {
              workspaceId: targetWorkspaceId
            });
          } else {
            if (flowId) emitter.emit("loading:end", {
              flowId
            });
          }
        }
      } catch (error) {
        console.error("[WorkspaceProvider] Error handling organization switch:", error);
        addNotice(workspaceNotices.fetchErrorOnSwitch);
        if (flowId) emitter.emit("loading:end", {
          flowId
        });
      }
    };
    emitter.on("organization:initialized", handleOrganizationInitialized);
    return () => emitter.off("organization:initialized", handleOrganizationInitialized);
  }, [emitter, client, router, organizationWorkspacePrefs, setStoredWorkspaceId, addNotice, t, pathname]);

  // Current workspace determination
  const currentWorkspaceId = useCurrentWorkspaceId({
    currentOrganization,
    hasInvalidWorkspaceInUrl,
    pathWorkspaceId,
    storedWorkspaceId,
    workspaces
  });
  const currentWorkspace = useMemo(() => workspaces.find(w => w.id === currentWorkspaceId) || null, [workspaces, currentWorkspaceId]);

  // Workspace actions
  const setCurrentWorkspace = useCallback((workspaceId: string, flowId?: string) => {
    const workspace = workspaces.find(w => w.id === workspaceId);
    if (workspace) {
      setStoredWorkspaceId(workspaceId);
      emitter.emit("navigation:navigate", {
        path: `/workspaces/${workspaceId}/home`,
        options: {
          replace: true
        },
        flowId
      });
      emitter.emit("workspace:switched", {
        workspaceId
      });
    }
  }, [workspaces, setStoredWorkspaceId, emitter]);
  const createWorkspace = useCallback(async (input: Omit<CreateWorkspaceInput, "organizationId">) => {
    if (!currentOrganization?.id) return;
    const flowId = "workspace-create-flow";
    try {
      emitter.emit("loading:start", {
        text: t("creating_workspace"),
        flowId
      });
      const newWorkspace = await createWorkspaceAPI(client as ApolloClient<NormalizedCacheObject>, {
        ...input,
        organizationId: currentOrganization.id
      });
      setWorkspaces(prev => [...prev, newWorkspace]);
      if (workspaces.length === 0) {
        setStoredWorkspaceId(newWorkspace.id);
      }
      addNotice(workspaceNotices.createSuccess);
    } catch (error) {
      console.error("[WorkspaceProvider] Failed to create workspace:", error);
      addNotice(workspaceNotices.createError);
      throw error;
    } finally {
      emitter.emit("loading:end", {
        flowId
      });
    }
  }, [currentOrganization?.id, client, workspaces.length, setStoredWorkspaceId, addNotice, emitter, t]);
  const updateWorkspace = useCallback(async (id: string, input: UpdateWorkspaceInput) => {
    const flowId = "workspace-update-flow";
    try {
      emitter.emit("loading:start", {
        text: t("updating_workspace"),
        flowId
      });
      const updatedWorkspace = await updateWorkspaceAPI(client as ApolloClient<NormalizedCacheObject>, id, input);
      setWorkspaces(prev => prev.map(w => w.id === updatedWorkspace.id ? updatedWorkspace : w));
      if (currentWorkspace?.id === updatedWorkspace.id) {
        setStoredWorkspaceId(updatedWorkspace.id);
      }
      addNotice(workspaceNotices.updateSuccess);
    } catch (error) {
      console.error("[WorkspaceProvider] Failed to update workspace:", error);
      addNotice(workspaceNotices.updateError);
      throw error;
    } finally {
      emitter.emit("loading:end", {
        flowId
      });
    }
  }, [client, currentWorkspace?.id, setStoredWorkspaceId, addNotice, emitter, t]);
  const deleteWorkspace = useCallback(async (id: string) => {
    const flowId = "workspace-delete-flow";
    try {
      emitter.emit("loading:start", {
        text: t("deleting_workspace"),
        flowId
      });
      await deleteWorkspaceAPI(client as ApolloClient<NormalizedCacheObject>, id);
      setWorkspaces(prev => prev.filter(w => w.id !== id));
      if (currentWorkspaceId === id) {
        const remainingWorkspaces = workspaces.filter(w => w.id !== id);
        setStoredWorkspaceId(remainingWorkspaces.length > 0 ? remainingWorkspaces[0].id : null);
      }
      addNotice(workspaceNotices.deleteSuccess);
    } catch (error) {
      console.error("[WorkspaceProvider] Failed to delete workspace:", error);
      addNotice(workspaceNotices.deleteError);
      throw error;
    } finally {
      emitter.emit("loading:end", {
        flowId
      });
    }
  }, [client, currentWorkspaceId, workspaces, setStoredWorkspaceId, addNotice, emitter, t]);
  return <WorkspaceContext.Provider value={{
    workspaces,
    currentWorkspace,
    currentWorkspaceId,
    setCurrentWorkspace,
    createWorkspace,
    updateWorkspace,
    deleteWorkspace,
    error,
    hasInvalidWorkspaceInUrl,
    loading
  }} data-sentry-element="unknown" data-sentry-component="WorkspaceProvider" data-sentry-source-file="WorkspaceProvider.tsx">
      {children}
    </WorkspaceContext.Provider>;
};
WorkspaceProvider.displayName = "WorkspaceProvider";