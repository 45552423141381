import { useContext } from "react";
import { InvitationsContext } from "@/providers/organizations/InvitationProvider";

export const useInvitations = () => {
  const context = useContext(InvitationsContext);
  if (!context) {
    throw new Error(
      "useInvitations must be used within an InvitationsProvider",
    );
  }
  return context;
};
