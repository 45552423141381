import { ReactNode } from "react"; // Import ReactNode
import { Menu, MenuItem, MenuTrigger, Popover } from "react-aria-components";
import type { MenuItemProps } from "react-aria-components";
import { Icon } from "@/edges/ui/Icon";
export interface ActionItem {
  id: number | string;
  name: string;
  variant?: "destructive";
  iconName?: string; // Add this line
  disabled?: boolean;
  onAction?: () => void;
  testId?: string;
}
export interface ActionMenuProps {
  items: ActionItem[];
  buttonComponent: ReactNode;
  align?: "left" | "right";
  size?: "sm" | "md";
  textAlign?: "left" | "right";
  className?: string;
  onAction?: (item: ActionItem) => void;
}
function ActionMenu({
  buttonComponent,
  items,
  className,
  align = "left",
  textAlign = "left",
  size = "md",
  onAction
}: ActionMenuProps) {
  return <div className={className} data-sentry-component="ActionMenu" data-sentry-source-file="ActionMenu.tsx">
      <MenuTrigger data-sentry-element="MenuTrigger" data-sentry-source-file="ActionMenu.tsx">
        {buttonComponent}
        <Popover className="w-fit overflow-auto rounded border bg-background-surface p-1 shadow-lg ring-borders ring-opacity-5 fill-mode-forwards entering:animate-in entering:fade-in entering:zoom-in-95 exiting:animate-out exiting:fade-out exiting:zoom-out-95 dark:ring-bordersDark" placement={align === "right" ? "bottom end" : "bottom start"} data-sentry-element="Popover" data-sentry-source-file="ActionMenu.tsx">
          <Menu className="outline-none" data-sentry-element="Menu" data-sentry-source-file="ActionMenu.tsx">
            {items.map(item => <ActionItem key={item.id} id={item.id} onAction={() => item.onAction?.() || onAction?.(item)} textAlign={textAlign} size={size} iconName={item.iconName} disabled={item.disabled} variant={item.variant} data-testid={item.testId}>
                {item.name}
              </ActionItem>)}
          </Menu>
        </Popover>
      </MenuTrigger>
    </div>;
}
interface ActionItemProps extends MenuItemProps {
  onAction?: () => void;
  textAlign?: "left" | "right";
  size?: "sm" | "md";
  iconName?: string; // Add this line
  disabled?: boolean;
  variant?: "destructive";
  children: ReactNode;
}
function ActionItem({
  textAlign = "left",
  iconName,
  size,
  disabled,
  children,
  variant,
  ...props
}: ActionItemProps) {
  return <MenuItem {...props} className={`group box-border flex w-full cursor-pointer items-center px-4 py-2 outline-none focus:bg-background-hover ${textAlign === "right" ? "justify-end pl-16" : "pr-16"} ${size === "sm" ? "text-sm" : ""} ${disabled ? "pointer-events-none opacity-50" : ""} ${variant === "destructive" ? "text-destructive" : ""}`} data-sentry-element="MenuItem" data-sentry-component="ActionItem" data-sentry-source-file="ActionMenu.tsx">
      {iconName && <Icon name={iconName} size={20} className="mr-2" />}
      {children}
    </MenuItem>;
}
export { ActionMenu };