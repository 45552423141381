import type { Notice } from "@/components/edges/ui/Notice/NoticeProvider";

type NoticeWithoutId = Omit<Notice, "id">;

export const workspaceNotices = {
  fetchError: {
    title: "Error",
    description: "Failed to load workspaces. Please try again.",
    variant: "error",
    persistent: true,
    position: "top-right",
  } as NoticeWithoutId,

  fetchErrorOnSwitch: {
    title: "Error",
    description: "Failed to load workspaces for the new organization",
    variant: "error",
    position: "top-right",
  } as NoticeWithoutId,

  createSuccess: {
    title: "Success",
    description: "Workspace created successfully",
    variant: "success",
    position: "top-right",
  } as NoticeWithoutId,

  createError: {
    title: "Error",
    description: "Failed to create workspace. Please try again.",
    variant: "error",
    position: "top-right",
  } as NoticeWithoutId,

  updateSuccess: {
    title: "Success",
    description: "Workspace updated successfully",
    variant: "success",
    position: "top-right",
  } as NoticeWithoutId,

  updateError: {
    title: "Error",
    description: "Failed to update workspace. Please try again.",
    variant: "error",
    position: "top-right",
  } as NoticeWithoutId,

  deleteSuccess: {
    title: "Success",
    description: "Workspace deleted successfully",
    variant: "success",
    position: "top-right",
  } as NoticeWithoutId,

  deleteError: {
    title: "Error",
    description: "Failed to delete workspace. Please try again.",
    variant: "error",
    position: "top-right",
  } as NoticeWithoutId,
} as const;
