"use client";

import React, { useState, useEffect } from "react";
import { twMerge } from "tailwind-merge";
interface SkeletonProps {
  width?: number | string;
  height?: number | string;
  variant?: "text" | "rect" | "circle";
  animation?: "pulse" | "wave" | "none";
  gradient?: boolean;
  flex?: boolean;
  stack?: (number | string)[]; // Array of widths for stacked skeletons
  responsive?: Record<string, string | number>;
  delay?: number;
  adjustAnimationSpeedBasedOnWidth?: boolean;
  ariaLabel?: string;
  className?: string;
  "data-testid"?: string;
}
export const Skeleton: React.FC<SkeletonProps> = ({
  width,
  height,
  variant = "rect",
  animation = "pulse",
  gradient = false,
  flex = false,
  stack,
  responsive,
  delay = 0,
  adjustAnimationSpeedBasedOnWidth = false,
  ariaLabel,
  className,
  "data-testid": dataTestId
}) => {
  const [visible, setVisible] = useState(delay === 0);
  useEffect(() => {
    if (delay > 0) {
      const timer = setTimeout(() => setVisible(true), delay);
      return () => clearTimeout(timer);
    }
  }, [delay]);
  if (!visible) return null;

  // Base styles
  const baseClasses = gradient ? "bg-gradient-to-r from-[var(--skeleton-base)] via-[var(--skeleton-highlight)] to-[var(--skeleton-base)]" : "bg-[var(--skeleton-base)]";

  // Adjust animation speed dynamically based on width
  const animationSpeed = adjustAnimationSpeedBasedOnWidth && width ? `animation-duration-[${Math.max(0.8, Number(width) / 100)}s]` : "";

  // Animation styles
  const animationClasses = animation === "pulse" ? `animate-pulse ${animationSpeed}` : animation === "wave" ? `relative overflow-hidden before:absolute before:inset-0 before:bg-gradient-to-r before:from-transparent before:via-[var(--skeleton-wave)] before:to-transparent before:animate-wave ${animationSpeed}` : "";

  // Shape-specific styles
  let shapeClasses = "rounded-sm";
  if (variant === "circle") shapeClasses = "rounded-full";
  if (variant === "text") shapeClasses = "h-4 w-full rounded-sm";

  // Dynamic layout handling
  const layoutClasses = flex ? "flex-1 min-w-0" : "";

  // Responsive styles
  const responsiveStyles = responsive ? Object.entries(responsive).map(([breakpoint, value]) => `${breakpoint}:w-[${value}]`).join(" ") : "";

  // Stacking Multiple Skeletons
  if (stack) {
    return <div className="space-y-2">
        {stack.map((lineWidth, index) => <div key={index} className={twMerge(baseClasses, animationClasses, shapeClasses, layoutClasses, responsiveStyles, className)} style={{
        width: lineWidth,
        height
      }} />)}
      </div>;
  }
  return <div className={twMerge(baseClasses, animationClasses, shapeClasses, layoutClasses, responsiveStyles, className)} style={{
    width,
    height
  }} role="presentation" aria-hidden={!ariaLabel} aria-label={ariaLabel ?? undefined} data-testid={dataTestId} data-sentry-component="Skeleton" data-sentry-source-file="Skeleton.tsx" />;
};
interface SkeletonGroupProps {
  count?: number;
  children: React.ReactNode;
  className?: string;
}
export const SkeletonGroup: React.FC<SkeletonGroupProps> = ({
  count = 1,
  children,
  className
}) => {
  return <div className={className} data-sentry-component="SkeletonGroup" data-sentry-source-file="Skeleton.tsx">
      {Array.from({
      length: count
    }).map((_, index) => <div key={index}>{children}</div>)}
    </div>;
};