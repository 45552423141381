"use client";

import { createContext, useState, ReactNode, useEffect } from "react";
import { acceptInviteAPI, sendInviteAPI, deleteInvitationAPI } from "@/api/invite";
import { useAuth } from "@/hooks/auth";
import { useOrganization } from "@/hooks/organization";
import { useNotice } from "@/components/edges/ui/Notice/NoticeProvider";
import { useApolloClient, ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { useRouter, usePathname } from "next/navigation";
import { useTranslations } from "next-intl";
import { Invitation } from "@/providers/auth/types";
import { gql } from "@apollo/client";
import { useEventEmitter } from "@/providers/event-bus";
import type { Events } from "@/providers/event-bus";
const DELETE_INVITATION = gql`
  mutation DeleteInvitation($input: DeleteInvitationInput!) {
    deleteInvitation(input: $input)
  }
`;
interface InvitationsContextType {
  invitations: Invitation[] | undefined;
  pendingInvitations: Invitation[] | undefined;
  acceptInvite: (inviteId: string) => Promise<void>;
  declineInvite: (inviteId: string) => Promise<void>;
  sendInvite: (email: string) => Promise<void>;
  loading: boolean;
  hasPendingInvites: boolean;
  actionInProgress: boolean;
}
export const InvitationsContext = createContext<InvitationsContextType | null>(null);
export const InvitationsProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [actionInProgress, setActionInProgress] = useState(false);
  const {
    user,
    refreshSession
  } = useAuth();
  const {
    currentOrganization
  } = useOrganization();
  const {
    addNotice
  } = useNotice();
  const client = useApolloClient();
  const emitter = useEventEmitter();
  const pathname = usePathname();
  const router = useRouter();

  // Get invitations directly from the user object
  // Only set invitations if user and user.invitations are defined
  const invitations = user?.invitations === undefined ? undefined : user?.invitations;

  // Computed property for pending invitations - only filter if invitations is defined
  const pendingInvitations = invitations === undefined ? undefined : invitations.filter(invite => invite.status === "PENDING");
  const hasPendingInvites = Boolean(pendingInvitations?.length);

  // Handle auth:signedIn event for initial redirection
  useEffect(() => {
    const handlePendingInvites = (event: Events["auth:hasPendingInvites"]) => {
      if (event.flowId) {
        emitter.emit("loading:update", {
          text: "Checking invitations...",
          flowId: event.flowId
        });
      }

      // Use router directly for navigation
      router.replace("/invitations");
    };
    emitter.on("auth:hasPendingInvites", handlePendingInvites);
    return () => {
      emitter.off("auth:hasPendingInvites", handlePendingInvites);
    };
  }, [emitter, pathname, router]);
  const acceptInvite = async (inviteId: string) => {
    try {
      setActionInProgress(true);
      await acceptInviteAPI(client as ApolloClient<NormalizedCacheObject>, inviteId);

      // Find the accepted invitation to get the organization ID
      const acceptedInvite = invitations?.find(inv => inv.id === inviteId);
      if (!acceptedInvite) {
        throw new Error("Invitation not found");
      }

      // Refresh the session with the new organization
      await refreshSession(acceptedInvite.organization.id);

      // Emit auth:signedIn event to trigger organization loading
      // This will trigger organization:initialized which will then trigger workspace routing
      if (user?.id) {
        emitter.emit("auth:signedIn", {
          userId: user.id
        });
      }
      addNotice({
        title: "Invitation Accepted",
        description: `You have joined ${acceptedInvite.organization.name}`,
        variant: "success",
        position: "top-right"
      });
    } catch (error) {
      console.error("Failed to accept invitation:", error);
      addNotice({
        title: "Error",
        description: "Failed to accept invitation. Please try again later.",
        variant: "error",
        position: "top-right"
      });
    } finally {
      setActionInProgress(false);
    }
  };
  const declineInvite = async (inviteId: string) => {
    try {
      setActionInProgress(true);
      await deleteInvitationAPI(client as ApolloClient<NormalizedCacheObject>, inviteId);

      // Refresh session to update invitations
      await refreshSession();
      addNotice({
        title: "Invitation Declined",
        description: "You have declined the invitation.",
        variant: "info",
        position: "top-right"
      });
    } catch (error) {
      console.error("Failed to decline invitation:", error);
      addNotice({
        title: "Error",
        description: "Failed to decline invitation. Please try again later.",
        variant: "error",
        position: "top-right"
      });
    } finally {
      setActionInProgress(false);
    }
  };
  const sendInvite = async (email: string) => {
    if (!currentOrganization) {
      addNotice({
        title: "Error",
        description: "No organization selected. Please select an organization first.",
        variant: "error",
        position: "top-right"
      });
      return;
    }
    try {
      setActionInProgress(true);
      await sendInviteAPI(client as ApolloClient<NormalizedCacheObject>, email, currentOrganization.id);
      addNotice({
        title: "Invitation Sent",
        description: `Invitation sent to ${email}`,
        variant: "success",
        position: "top-right"
      });
    } catch (error) {
      console.error("Failed to send invitation:", error);
      addNotice({
        title: "Error",
        description: "Failed to send invitation. Please try again later.",
        variant: "error",
        position: "top-right"
      });
    } finally {
      setActionInProgress(false);
    }
  };
  return <InvitationsContext.Provider value={{
    invitations,
    pendingInvitations,
    acceptInvite,
    declineInvite,
    sendInvite,
    loading: user === undefined || invitations === undefined,
    hasPendingInvites,
    actionInProgress
  }} data-sentry-element="unknown" data-sentry-component="InvitationsProvider" data-sentry-source-file="InvitationProvider.tsx">
      {children}
    </InvitationsContext.Provider>;
};
InvitationsProvider.displayName = "InvitationsProvider";