import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { gql } from "@apollo/client";

// GraphQL Queries and Mutations
const GET_WORKSPACES = gql`
  query GetWorkspaces($orgId: String!) {
    workspaces(orgId: $orgId) {
      id
      name
      createdAt
      updatedAt
      organizationId
    }
  }
`;

// const SWITCH_WORKSPACE = gql`
//   mutation SwitchWorkspace($workspaceId: ID!) {
//     switchWorkspace(workspaceId: $workspaceId) {
//       id
//       name
//       description
//       isDefault
//     }
//   }
// `;

const CREATE_WORKSPACE = gql`
  mutation CreateWorkspace($input: CreateWorkspaceInput!) {
    createWorkspace(input: $input) {
      id
      name
    }
  }
`;

const UPDATE_WORKSPACE = gql`
  mutation UpdateWorkspace($id: String!, $input: UpdateWorkspaceInput!) {
    updateWorkspace(id: $id, input: $input) {
      id
      name
    }
  }
`;

// const DELETE_WORKSPACE = gql`
//   mutation DeleteWorkspace($id: ID!) {
//     deleteWorkspace(id: $id) {
//       id
//     }
//   }
// `;

// API Functions
export interface Workspace {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  organizationId: string;
}

export interface CreateWorkspaceInput {
  name: string;
  description?: string;
  organizationId: string;
}

export interface UpdateWorkspaceInput {
  name: string;
}

export const getWorkspacesAPI = async (
  client: ApolloClient<NormalizedCacheObject>,
  organizationId: string,
): Promise<Workspace[]> => {
  const { data } = await client.query({
    query: GET_WORKSPACES,
    variables: { orgId: organizationId },
  });
  return data.workspaces;
};

// export const switchWorkspaceAPI = async (
//   client: ApolloClient<NormalizedCacheObject>,
//   workspaceId: string,
// ): Promise<Workspace> => {
//   const { data } = await client.mutate({
//     mutation: SWITCH_WORKSPACE,
//     variables: { workspaceId },
//   });
//   return data.switchWorkspace;
// };

export const createWorkspaceAPI = async (
  client: ApolloClient<NormalizedCacheObject>,
  input: CreateWorkspaceInput,
): Promise<Workspace> => {
  const { data } = await client.mutate({
    mutation: CREATE_WORKSPACE,
    variables: { input },
  });
  return data.createWorkspace;
};

export const updateWorkspaceAPI = async (
  client: ApolloClient<NormalizedCacheObject>,
  id: string,
  input: UpdateWorkspaceInput,
): Promise<Workspace> => {
  const { data } = await client.mutate({
    mutation: UPDATE_WORKSPACE,
    variables: { id, input },
  });
  return data.updateWorkspace;
};

export const deleteWorkspaceAPI = async (
  client: ApolloClient<NormalizedCacheObject>,
  id: string,
): Promise<{ id: string }> => {
  // const { data } = await client.mutate({
  //   mutation: DELETE_WORKSPACE,
  //   variables: { id },
  // });
  // return data.deleteWorkspace;
  return { id };
};
