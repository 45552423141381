import { gql } from "@apollo/client";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";

const GET_USER_INVITES = gql`
  query GetUserInvites {
    me {
      invitations {
        id
        email
        organization {
          id
          name
        }
        status
      }
    }
  }
`;

const ACCEPT_INVITE = gql`
  mutation AcceptInvite($input: AcceptInvitationInput!) {
    acceptInvitation(input: $input) {
      id
      status
    }
  }
`;

// const DECLINE_INVITE = gql`
//   mutation DeclineInvite($input: DeclineInvitationInput!) {
//     declineInvitation(input: $input) {
//       id
//       status
//     }
//   }
// `;

// const SEND_INVITE = gql`
//   mutation SendInvite($input: SendInvitationInput!) {
//     sendInvitation(input: $input) {
//       id
//       email
//       status
//     }
//   }
// `;

const DELETE_INVITATION = gql`
  mutation DeleteInvitation($input: DeleteInvitationInput!) {
    deleteInvitation(input: $input)
  }
`;

export interface Invitation {
  id: string;
  email: string;
  organization: {
    id: string;
    name: string;
  };
  status: "pending" | "accepted" | "declined";
}

export const getUserInvitesAPI = async (
  client: ApolloClient<NormalizedCacheObject>,
): Promise<Invitation[]> => {
  const { data } = await client.query({
    query: GET_USER_INVITES,
  });
  return data.me.invitations;
};

export const acceptInviteAPI = async (
  client: ApolloClient<NormalizedCacheObject>,
  inviteId: string,
): Promise<void> => {
  await client.mutate({
    mutation: ACCEPT_INVITE,
    variables: {
      input: {
        invitationId: inviteId,
      },
    },
  });
};

// export const declineInviteAPI = async (
//   client: ApolloClient<NormalizedCacheObject>,
//   inviteId: string,
// ): Promise<void> => {
//   await client.mutate({
//     mutation: DECLINE_INVITE,
//     variables: {
//       input: {
//         invitationId: inviteId,
//       },
//     },
//   });
// };

export const sendInviteAPI = async (
  client: ApolloClient<NormalizedCacheObject>,
  email: string,
  orgId: string,
): Promise<void> => {
  // await client.mutate({
  //   mutation: SEND_INVITE,
  //   variables: {
  //     input: {
  //       email,
  //       organizationId: orgId,
  //     },
  //   },
  // });
};

export const deleteInvitationAPI = async (
  client: ApolloClient<NormalizedCacheObject>,
  inviteId: string,
): Promise<void> => {
  await client.mutate({
    mutation: DELETE_INVITATION,
    variables: {
      input: {
        id: inviteId,
      },
    },
  });
};
