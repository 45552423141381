import { useMemo, useEffect } from "react";
import { Workspace } from "@/api/workspace";
import { usePathname, useRouter } from "next/navigation";

export function useWorkspaceIdFromPath() {
  const pathname = usePathname();
  return useMemo(() => {
    if (pathname) {
      const match = pathname.match(/\/workspaces\/([^/]+)/);
      return match?.[1] || null;
    }
    return null;
  }, [pathname]);
}

export function useInvalidWorkspaceUrl(
  pathWorkspaceId: string | null,
  workspaces: Workspace[],
) {
  const pathname = usePathname();
  const router = useRouter();

  useEffect(() => {
    // Only redirect if we're on a workspace route
    if (!pathname?.includes("/workspaces/")) return;

    // Skip if we don't have a workspace ID in the path
    if (!pathWorkspaceId) return;

    // Skip if workspaces aren't loaded yet
    if (!workspaces.length) return;

    // Check if the workspace ID in the URL exists in our loaded workspaces
    const workspaceExists = workspaces.some((w) => w.id === pathWorkspaceId);

    // If the workspace doesn't exist and we're inside a specific workspace route, redirect
    if (
      !workspaceExists &&
      pathname?.includes(`/workspaces/${pathWorkspaceId}`)
    ) {
      // Redirect to workspace selector
      router.replace("/workspaces");
    }
  }, [pathname, pathWorkspaceId, workspaces, router]);

  return useMemo(() => {
    // Only check if we're on a workspace route
    if (!pathname?.includes("/workspaces/")) return false;

    // If we don't have workspaces loaded yet, we can't be sure it's invalid
    if (!workspaces.length) return false;

    // If we have a workspace ID in the URL, check if it exists in our loaded workspaces
    if (pathWorkspaceId) {
      return !workspaces.some((w) => w.id === pathWorkspaceId);
    }

    return false;
  }, [pathname, pathWorkspaceId, workspaces]);
}

export function useCurrentWorkspaceId({
  currentOrganization,
  hasInvalidWorkspaceInUrl,
  pathWorkspaceId,
  storedWorkspaceId,
  workspaces,
}: {
  currentOrganization: { id: string } | null;
  hasInvalidWorkspaceInUrl: boolean;
  pathWorkspaceId: string | null;
  storedWorkspaceId: string | null;
  workspaces: Workspace[];
}) {
  return useMemo(() => {
    if (!currentOrganization || hasInvalidWorkspaceInUrl) return null;

    // If we have a valid workspace ID in the URL, use it
    if (pathWorkspaceId && workspaces.some((w) => w.id === pathWorkspaceId)) {
      return pathWorkspaceId;
    }

    // No URL workspace, try stored workspace
    if (
      storedWorkspaceId &&
      workspaces.some((w) => w.id === storedWorkspaceId)
    ) {
      return storedWorkspaceId;
    }

    // Fallback to first workspace
    return workspaces.length > 0 ? workspaces[0].id : null;
  }, [
    currentOrganization,
    hasInvalidWorkspaceInUrl,
    pathWorkspaceId,
    storedWorkspaceId,
    workspaces,
  ]);
}
